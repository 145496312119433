import React, { Component } from 'react';
import consts from '../utils/constants'

import NavBar from "../components/NavBarInterna";
import ContactUs from "../components/ContactUs"
import Footer from "../components/Footer";

class SiteContact extends Component {
    render() {
        const page = this.props.page
        const [site, logo, logoNegativo] = consts.getInternalPageData(page)

        return (
            <div className={`internal-page contact-page ${page}-page`}>
                <div className="main-container">                    
                    <NavBar
                        site={site}
                        logo={logo}
                        logoNegativo={logoNegativo}
                        page={page}/>
                    <ContactUs
                        page={page}
                    />
                    <Footer companyName={site} />
                </div>
            </div>
        );
    }
}
export default SiteContact;