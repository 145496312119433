let siteId = 3;
const url = process.env.REACT_APP_API_URL;

export default {
  PAGE_UNIAO_SEGURADORA: "uniao-seguradora",
  APP_NAME_UNIAO_SEGURADORA: "União Seguradora",
  LOGO_UNIAO_SEGURADORA: require("../../src/assets/img/logo-uniao-seguradora.png"),
  LOGO_NEGATIVO_UNIAO_SEGURADORA: require("../../src/assets/img/logo-uniao-seguradora-negativo.png"),
  STORAGE_URL: "https://storage.googleapis.com/aspecirlv",
  PORTAL_CLIENTE_URL: '"https://cliente.grupoaspecir.com.br"',

  getSiteUrl(page) {
    switch (page) {
      case this.PAGE_UNIAO_SEGURADORA:
        return `${url}/sites/${siteId}`;
      default:
        return null;
    }
  },

  getSitePageUrl() {
    return `${url}/sites/${siteId}`;
  },

  getContact() {
    return `${url}/contato`;
  },

  getNewUrl() {
    return `${url}/noticias`;
  },

  getContentUrl() {
    return `${url}/sites/${siteId}/conteudo`;
  },

  getNewsUrl(page) {
    return `${url}/sites/${siteId}/noticias?page=${page || "1"}`;
  },

  getRepresentative() {
    return url + "/representatives";
  },

  getHolidaysUrl() {
    return `${url}/holidays_config?active_holiday=true`;
  },

  getApiChatBotUrl() {
    return url + "/chat_bot";
  },

  getInternalPageData(page) {
    let site, logo, logoNegativo;

    switch (page) {
      case this.PAGE_UNIAO_SEGURADORA:
        site = this.APP_NAME_UNIAO_SEGURADORA;
        logo = this.LOGO_UNIAO_SEGURADORA;
        logoNegativo = this.LOGO_NEGATIVO_UNIAO_SEGURADORA;
        break;
      default:
        return;
    }

    return [site, logo, logoNegativo];
  },
};
