import React from "react";
import ReactHtmlParser from "react-html-parser";

export default (props) => {
	return (
		<React.Fragment>
			<div>
				<h4 className="mb-0">{props.plan.title}</h4>
				<div className="mt-2">{ReactHtmlParser(props.plan.text)}</div>
			</div>
		</React.Fragment>
	);
};
