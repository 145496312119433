import React, { Component } from 'react';
import consts from '../utils/constants';
import NavBar from "../components/NavBarInterna";
import Footer from "../components/Footer";

export type FileType = {
  name: string,
  description: string,
  path: string,
  extension: string,
}

class SiteTravelInsurance extends Component {

  render() {
    const page = this.props.page
    const [site, logo, logoNegativo] = consts.getInternalPageData(page)
    const condicoesGeraisUrl = consts.STORAGE_URL + "/condicoes_gerais/uniao_seguradora/"
    const files = [
      {
        name: '25.1. RG Incentivo - UNIÃO SEGURADORA - 10 mil - Segurado v.GR 22-09-23',
        description: '',
        path: condicoesGeraisUrl + "25.1.%20RG%20Incentivo%20-%20UNIÃO%20SEGURADORA%20-%2010%20mil%20-%20Segurado%20v.GR%2022-09-23.pdf",
        extension: 'pdf'
      },
      {
        name: '25.2. RG Incentivo - UNIÃO SEGURADORA - 10 mil - vendedor v.GR 22-09-23',
        description: '',
        path: condicoesGeraisUrl + "25.2.%20RG%20Incentivo%20-%20UNI%C3%83O%20SEGURADORA%20-%2010%20mil%20-%20vendedor%20v.GR%2022-09-23.pdf",
        extension: 'pdf'
      },
      {
        name: '26.1. Condicoes Gerais de Assistencia Viagem WTA - UNIÃO SEGURADORA',
        description: '',
        path: condicoesGeraisUrl + "26.1.%20Condicoes%20Gerais%20de%20Assistencia%20Viagem%20WTA%20-%20UNI%C3%83O%C2%A0SEGURADORA.pdf",
        extension: 'pdf'
      },
      {
        name: 'Condições Gerais Seguro Viagem - Assistencias E Sorteios',
        description: '',
        path: condicoesGeraisUrl + "Condi%C3%A7%C3%B5es%20Gerais%20Seguro%20Viagem%20-%20Assistencias%C2%A0E%C2%A0Sorteios.pdf",
        extension: 'pdf'
      },
    ];

    return (
      
      <div className={`internal-page contact-page ${page}-page`}>
        <div className="main-container">                    
            <NavBar site={site} logo={logo} logoNegativo={logoNegativo} page={page}/>
            <div className="container">
              <div className="travel-insurance-page">
                <h2 className="section-title">Condições Gerais do Seguro Viagem</h2>
                <div className="travel-insurance-files">
                  {Object.values(files).map((item:FileType, index) => (
                    <div className="travel-insurance-frame frame-simple" key={index} >
                      <div className="icon-container"><a href={item.path} target="_blank" rel="noopener noreferrer"><i className="icofont-file-document" /></a></div>
                      <div className="file-content">
                        <div className="file-information">
                          <div><strong>Nome: </strong>{item.name}</div>
                          <div><strong>Tipo: </strong>{item.extension}</div>
                          <div><a href={item.path} target="_blank" rel="noopener noreferrer"><i className="icofont-download" /> Download</a></div>
                        </div>
                        <div className="file-description"><small>Descrição</small><p>{item.description}</p></div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <Footer companyName={site} />
        </div>
      </div>
    );
  }
};

export default SiteTravelInsurance;


