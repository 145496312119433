import React, { Component } from "react";
import OwlCarousel from "react-owl-carousel3";
import PropTypes from "prop-types";
import VisibilitySensor from "react-visibility-sensor";

import { Link } from "react-router-dom";

import BannerText from "../components/banner/Text";

class HomeSlides extends Component {
	render() {
		if (!this.props.homeslidesData.length) return null;
		//Home Slides loop start
		const homeslidesdata = this.props.homeslidesData.map((homeslides, index) => (
			<React.Fragment key={index}>
				<div className="main-banner" style={{ backgroundImage: "url('" + homeslides.image + "')" }}>
					<div className="d-table">
						<div className="d-table-cell">
							<div className="container">
								<VisibilitySensor>
									{({ isVisible }) => (
										<div className="hero-content">
											{homeslides.link ? (
												homeslides.key ? (
													<Link
														to={{
															pathname: homeslides.link,
															state: { key: homeslides.key },
														}}
													>
														<BannerText
															isVisible={isVisible}
															topTitle={homeslides.top_title}
															titleLeft={homeslides.title ? homeslides.title : homeslides.title_left}
															titleSpan={homeslides.title_span}
															titleRight={homeslides.title_right}
															content={homeslides.content}
														/>
													</Link>
												) : (
													<a href={homeslides.link} target="_blank" rel="noopener noreferrer">
														<BannerText
															isVisible={isVisible}
															topTitle={homeslides.top_title}
															titleLeft={homeslides.title ? homeslides.title : homeslides.title_left}
															titleSpan={homeslides.title_span}
															titleRight={homeslides.title_right}
															content={homeslides.content}
														/>
													</a>
												)
											) : (
												<BannerText
													isVisible={isVisible}
													topTitle={homeslides.top_title}
													titleLeft={homeslides.title ? homeslides.title : homeslides.title_left}
													titleSpan={homeslides.title_span}
													titleRight={homeslides.title_right}
													content={homeslides.content}
												/>
											)}
										</div>
									)}
								</VisibilitySensor>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		));
		//Home Slides END
		return (
			<React.Fragment>
				<div id="home">
					<OwlCarousel
						className="home-slides owl-theme"
						items={1}
						nav={true}
						dots={this.props.homeslidesData.length > 1 ? true : false}
						loop={true}
						touchDrag={false}
						mouseDrag={false}
						autoplay={true}
						autoplayHoverPause={true}
						animateOut={"slideOutDown"}
						animateIn={"slideInDown"}
						smartSpeed={700}
						autoplayTimeout={8000}
						navText={[
							"<i class='icofont-swoosh-right'></i>",
							"<i class='icofont-swoosh-right'></i>",
						]}
					>
						{homeslidesdata}
					</OwlCarousel>
				</div>
			</React.Fragment>
		);
	}
}

HomeSlides.propTypes = {
	homeslidesData: PropTypes.array,
};

HomeSlides.defaultProps = {
	homeslidesData: [
		{
			top_title: "",
			title_left: "",
			title_span: "",
			title_right: "",
			content: "",
			button: "",
			link: "#",
			image: {
				url: "",
			},
		},
	],
};

export default HomeSlides;
