import React, { Component } from "react";

import consts from "../utils/constants";

import NavBar from "../components/NavBarInterna";
import Footer from "../components/Footer";
import Partners from "../components/Partners";

class SitePartners extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ loading: false });
    }, 200);
  }

  render() {
    const page = this.props.page;
    const [site, logo, logoNegativo] = consts.getInternalPageData(page);

    return (
      <div className={`internal-page plans-page ${page}-page`}>
        <div className="partners-main-container">
          <NavBar
            site={site}
            logo={logo}
            logoNegativo={logoNegativo}
            page={page}
          />
          <Partners />
          <Footer companyName={site} />
        </div>
      </div>
    );
  }
}
export default SitePartners;
