import React, { Component } from "react"
import { Link } from "react-router-dom"
import consts from '../utils/constants'

import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap"
import Icofont from "react-icofont"
import SideNav from "../components/SideNav"

class NavBar extends Component {
    closeNavbar() {
        if (window.matchMedia("screen and (max-width: 991px)").matches) {
            document.getElementById("collaspe-btn").click();
        }
    }
    render() {
        const condicoesGeraisUrl = consts.STORAGE_URL + "/condicoes_gerais/uniao_seguradora/"

        return (
            <React.Fragment>
                <Navbar
                    sticky="top"
                    id="navbar"
                    bg="light"
                    expand="xl"
                    className="navbar navbar-expand-xl"
                    collapseOnSelect={true}
                >
                    <Container>
                        <Navbar.Brand >
                            <Link to="/">
                                <img src={this.props.logoNegativo} alt={`Logo ${this.props.site}`} />
                            </Link>
                        </Navbar.Brand>
                        <Navbar.Toggle
                            aria-controls="basic-navbar-nav"
                            id="collaspe-btn" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="ml-auto">
                                <Nav.Item>
                                    <Link
                                        to="/"
                                        className="nav-link"
                                        onClick={this.closeNavbar}
                                    >Home
                                    </Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Link                                        
                                        to="/sobre"                                       
                                        className="nav-link"
                                        onClick={this.closeNavbar}
                                    >
                                        A Empresa
                                    </Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Link
                                        to="/produtos"
                                        className="nav-link"
                                        onClick={this.closeNavbar}
                                    >Produtos
                                    </Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Link
                                        to="/noticias"
                                        className="nav-link"
                                        onClick={this.closeNavbar}
                                    >Notícias
                                    </Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Link
                                        to="/contato"
                                        className="nav-link"
                                        onClick={this.closeNavbar}
                                    >Contato
                                    </Link>
                                </Nav.Item>
                                <NavDropdown title="Condições Gerais" id="basic-nav-dropdown">
                                    <NavDropdown.Item href={condicoesGeraisUrl + "CG_VG_UNIAO_102013.pdf"} target="_blank">Seguro de Vida em Grupo</NavDropdown.Item>
                                    <NavDropdown.Item href={condicoesGeraisUrl + "CG_APC_UNIAO_072013.pdf"} target="_blank">Seguro de Acidentes Pessoais</NavDropdown.Item>
                                    <NavDropdown.Item href={condicoesGeraisUrl + "CG_PREST_UNIAO_102013.pdf"} target="_blank">Seguro Prestamista</NavDropdown.Item>
                                    <NavDropdown.Item href={condicoesGeraisUrl + "CG_AF_UNIAO_072013.pdf"} target="_blank">Seguro de Auxílio Funeral</NavDropdown.Item>                                    
                                    <NavDropdown.Item href="/seguro-viagem">Seguro Viagem</NavDropdown.Item>
                                </NavDropdown>
                                <Nav.Item>
                                    <a href="https://www.aspecir.com.br/login.aspx"
                                        className="nav-link"
                                    >
                                        <Icofont icon="lock" />
                                        Portal do Cliente</a>
                                </Nav.Item>
                                <Nav.Item>
                                    <SideNav
                                        page={this.props.page}
                                        logo={this.props.logo}
                                        site={this.props.logoNegativo}
                                        locationbolt="Praça Otávio Rocha, 65 - 1º andar"
                                        locationnormal="Centro Histórico, 90020-140, Porto Alegre/RS"
                                        phonebolt="+55 (51) 3228.1999"
                                        mailbolot="atendimento@aspecir.com.br"
                                        hasSocialLinks={true}
                                        facebooklink="http://www.facebook.com/grupoaspecir/"
                                    />
                                </Nav.Item>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </React.Fragment>
        );
    }
}

export default NavBar;
