import React from "react"

export default props => (
    <React.Fragment >
        <h5
            className={
                props.isVisible
                    ? "animated fadeInDown slow opacityOne"
                    : "opacityZero"
            }
        >
            {props.topTitle}
        </h5>
        <h1
            className={
                props.isVisible
                    ? "animated fadeInUp slow opacityOne"
                    : "opacityZero"
            }
        >
            {props.titleLeft}{" "}
            <span>
                {
                    props.titleSpan
                }
            </span>{" "}
            {props.titleRight}
        </h1>
        <p
            className={
                props.isVisible
                    ? "animated zoomIn Slow opacityOne"
                    : "opacityZero"
            }
        >
            {props.content}
        </p>
    </React.Fragment >
)