import React, { Component } from 'react';
import axios from 'axios';
import consts from '../utils/constants'

import NavBar from "../components/NavBarInterna";
import Container from "../components/news/Container";
import Footer from "../components/Footer";

class SiteNews extends Component {
    constructor(props) {
        super(props)
        this.state = {
            news: [],
            isLoading: true
        }
    }

    componentDidMount() {
        axios.get(consts.getNewsUrl())
            .then(resp =>
                this.setState({
                    ...this.state,
                    news: resp.data.data.posts,
                    isLoading: false
                })
            )
    }

    render() {
        const page = this.props.page
        const [site, logo, logoNegativo] = consts.getInternalPageData(page)

        return (
            <div className={`internal-page news-page ${page}-page`}>
                <div className="main-container">                    
                    <NavBar
                        site={site}
                        logo={logo}
                        logoNegativo={logoNegativo}
                        page={page} />

                    {this.state.isLoading ?
                        <div className="loader"></div>
                        :
                        <Container
                            asideData={this.state.news}
                            newId={this.props.location.state ? this.props.location.state.id : null}
                        />
                    }
                    <Footer companyName={site} />
                </div>
            </div>
        );
    }
}
export default SiteNews;


