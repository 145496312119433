import React, { Component } from 'react'

import consts from '../utils/constants'

import NavBar from "../components/NavBarInterna"
import Footer from "../components/Footer"
import About from "../components/About"

class SitePlansProducts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true
        };
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({ loading: false });
        }, 200);
    }

    render() {
        const page = this.props.page
        const [site, logo, logoNegativo] = consts.getInternalPageData(page)

        return (
            <div className={`internal-page plans-page ${page}-page`}>
                <div className="main-container">
                    <NavBar
                        site={site}
                        logo={logo}
                        logoNegativo={logoNegativo}
                        page={page} />
                    {this.state.loading ?
                        <div className="loader"></div>
                        :
                        <About
                            item2topTitle="Sobre a"
                            item2topTitlespan="Empresa"
                            item2Title="Conheça um pouco sobre a"
                            item2Titlespan="União Seguradora"
                            sectionImage={require("../assets/img/familia-casa.jpg")}
                            item2ContentTop="Em 1913, 57 caixeiros-viajantes reuniram-se para criar uma sociedade que defendesse os interesses da classe. Foi fundada, então, na cidade de Santa Maria, a SUCV - Sociedade União dos Caixeiros-Viajantes do Rio Grande do Sul com a intenção de auxiliar os familiares de seus participantes que viessem a perecer durante suas longas jornadas de trabalho pelo Estado. A ideia evoluiu e a SUCV transformou-se na SUCV - União de Previdência. Hoje, a União Seguradora S/A, oriunda da SUCV ? União de Previdência, foi transformada em Sociedade Anônima e está estabelecida na Capital Gaúcha, com o objetivo de ampliar e consolidar as suas atividades no ramo Vida e Previdência. Sempre preservando um apreciável patrimônio cultural, histórico, moral e econômico que a coloca na posição privilegiada de uma das mais importantes entidades do sistema de previdência no país."
                            item2ContentMiddle="É assim, com muita determinação, que a empresa apresenta ao seu público os produtos que irão assegurar confiança e tranquilidade a sua família: Coberturas por morte qualquer causa, acidentes pessoais, auxílio funeral e seguro prestamista. É possível também contratar coberturas adicionais como inclusão de cônjuge e filhos, invalidez funcional permanente total por doença, auxílio alimentação, invalidez permanente total ou parcial por acidente, diárias por incapacidade por acidente pessoal, despesas médicas, hospitalares e odontológicas, além de auxílio financeiro para associados."
                            item2ContentBottom="A União Seguradora S/A, está pronta para atender a todas as necessidades em termos de seguro e proteção, sendo sua comercialização sempre realizada pelo corretor profissional de seguros, pois tem passado sólido e futuro promissor."
                        />
                    }
                    <Footer companyName={site} />
                </div>
            </div>
        );
    }
}
export default SitePlansProducts;


