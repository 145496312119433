import styled from "styled-components";

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;

  &::before {
    position: absolute;
    content: " ";
    width: 100%;
    height: 100%;
    background-color: rgb(0 0 0/0.8);
  }
`;

export const Modal = styled.div`
  position: relative;
  z-index: 11;
  max-width: 35.25em;
  margin: 1em;
  max-height: 90%;
  overflow-y: auto;

  & .modal-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.2em;
    padding: 0 !important;
    border-bottom: none;

    & h2 {
      color: #f1f1f1;
      font-size: 1.3rem;
      margin: 0 0 0.1em 0.2em;
      text-transform: uppercase;
      font-weight: 500;
    }
  }

  & .modal-content {
    background-color: #005b9f;
    padding: 1em;
    border-bottom-left-radius: 0.7em;
    border-top-right-radius: 0.7em;

    & p {
      color: #fff;
      font-size: 19px;
    }

    & .modal-main {
      text-align: justify;
      text-justify: inter-word;
      color: #fff;
    }
  }

  @media (min-width: 35.25em) {
    & .modal-header {
      margin-bottom: 0;
      & h2 {
        font-size: 2rem;
      }
    }
  }
`;

export const Button = styled.button`
  margin-left: 1em;
  height: 2em;
  display: flex;
  align-items: center;
  background-color: transparent;
  border: transparent;
  border-radius: 0.5em;
  padding: 0;
  color: #005b9f;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    background-color: #005b9f;
    color: #fff;

    & .button-icon {
      background-color: #005b9f;
    }
  }

  & span {
    display: none;
    margin: 0 0.5em;
  }

  & .button-icon {
    background-color: #005b9f;
    color: #f1f1f1;
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 0.5em;
    border-radius: 0.5em;
    transition: 0.3s;
  }

  @media (min-width: 35.25em) {
    margin-top: 0.2em;
    background-color: #f1f1f1;
    margin-right: 0.2em;

    & span {
      display: block;
    }

    & .button-icon {
      margin-right: -0.2em;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
`;
