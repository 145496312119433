import React from "react";
import ReactHtmlParser from 'react-html-parser';

export default props => (
  <React.Fragment>
    <div className="news-main">
      <h2 className="news-title">{props.new.title}</h2>
      <small>{props.new.created_at}</small>      
      <div className="mt-2">{ReactHtmlParser(props.new.text)}</div>
    </div>
  </React.Fragment>
)