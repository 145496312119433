import React, { useState, useEffect } from "react";
import { Accordion, AccordionItem } from "react-sanfona";

export default function Aside(props) {
	const [expandedItems, setExpandedItems] = useState({});

	useEffect(() => {
		props.asideData.forEach((plan) => {
			if (window.location.href.includes(plan.key)) {
				setExpandedItems((prevExpandedItems) => ({
					...prevExpandedItems,
					[plan.key]: true,
				}));
			} else if (!window.location.href.includes('#')) {
				setExpandedItems((prevExpandedItems) => ({
					...prevExpandedItems,
					[props.defaultPlanKey]: true,
				}));
			}
		});
	}, [props]);


	const handleItemClick = (planKey) => {
		setExpandedItems((prevExpandedItems) => ({
			...prevExpandedItems,
			[planKey]: !prevExpandedItems[planKey],
		}));
	};

	return (
		<React.Fragment>
			<Accordion className="plans-aside-list">
				{props.asideData.map((plan, index) => {
					return (
						<AccordionItem
							title={plan.title}
							className="plan-aside-item"
							expanded={expandedItems[plan.key]}
							onClick={() => handleItemClick(plan.key)}
							key={index}
						>
							{plan.topics.map((topic, i) => (
								<div
									key={i}
									className={
										"plan-aside-subitem" +
										(props.currentPlan.key === topic.key ? " active" : "")
									}
									onClick={() => props.handleAsideClick(plan.banner, topic, plan.key)}
								>
									{topic.title}
								</div>
							))}
						</AccordionItem>
					)
				})}
			</Accordion>
		</React.Fragment>
	);
}