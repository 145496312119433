import React, { useState, useEffect } from "react";
import { FaTimes } from "react-icons/fa";

import axios from "axios";

import { Wrapper, Modal, Button } from "./styles";
import consts from "../../utils/constants";

const HolidayAlert = () => {
  const [warnVisible, setWarnVisible] = useState(true);
  const [isVisible, setIsVisible] = useState(false);
  const [checked, setChecked] = useState(false);
  const [holiday, setHoliday] = useState({
    holiday: false,
    text: "",
    name: "",
  });

  const closeModal = () => {
    setIsVisible(false);
  };

  useEffect(() => {
    axios.get(consts.getHolidaysUrl()).then((resp) => {
      let data = resp.data.results[0];
      if (resp.data.results.length > 0) {
        setHoliday({
          holiday: true,
          name: data.name,
          text: data.text,
        });
      } else {
        setHoliday({
          holiday: false,
          name: "",
          text: "",
        });
      }
    });
  }, []);

  // Check if the alert was already been seen
  useEffect(() => {
    if (!checked) {
      setIsVisible(true);
    }

    setChecked(true);
  }, [checked]);

  return (
    <>
      {isVisible && holiday.holiday ? (
        <Wrapper>
          <Modal>
            <div className="modal-header">
              <h2>{holiday.name}</h2>
              <Button onClick={closeModal}>
                <span>Fechar</span>
                <div className="button-icon">
                  <FaTimes />
                </div>
              </Button>
            </div>
            <div className="modal-content">
              <p>{holiday.text}</p>
            </div>
          </Modal>
        </Wrapper>
      ) : null}
      {warnVisible && (
        <Wrapper>
          <Modal>
            <div className="modal-header">
              <h2 style={{fontSize: '1.5rem'}}>COMUNICADO AOS ACIONISTAS</h2>
              <Button onClick={() => setWarnVisible(false)}>
                <span>Fechar</span>
                <div className="button-icon">
                  <FaTimes />
                </div>
              </Button>
            </div>
            <div className="modal-content">
              <p>
                UNIÃO Seguradora S.A. - Vida e Previdência, CNPJ 95.611.141/0001-57, COMUNICA aos seus acionistas que se encontra disponível para saque os valores de Juros ao Capital e Dividendos aos titulares das ações da companhia. Informações e procedimentos podem ser obtidos na sede da companhia sito à Praça Otávio Rocha, 65, 2° andar, Centro, Porto Alegre, Rio Grande do Sul, e-mail: <b>secretaria@uniaoseguradora.com.br</b> ou telefone <b>+55 51 3061.9606</b>.<br/>
                <br/>
                A Direção
              </p>
            </div>
          </Modal>
        </Wrapper>
      )}
    </>
  );
};

export default HolidayAlert;
