import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";

class ModalCertificate extends Component {
  render() {
    const { show, handleClose } = this.props;

    return (
      <>
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <h5>
              Solicitação enviada!
            </h5>
          </Modal.Header>
          <Modal.Body className=" text-justify">
            Aguarde que entraremos em contato pelo número informado para andamento da solicitação.
          </Modal.Body>
          <Modal.Footer>
            <Button variant="btn btn-primary" onClick={handleClose}>
                Entendi
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default ModalCertificate;
