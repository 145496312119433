export default {    
    setPhoneMask(v) {
        v = v.replace(/\D/g, "")
        v = v.replace(/^(\d\d)(\d)/g, "($1) $2")

        if (v.length === 14)
            v = v.replace(/(\d{5})(\d)/, "$1-$2")
        else
            v = v.replace(/(\d{4})(\d)/, "$1-$2")

        return v
    },
}