import React, { Component } from "react";
import axios from "axios";

import consts from "../utils/constants";

import NavBar from "../components/NavBarInterna";
import Container from "../components/plans/Container";
import Footer from "../components/Footer";

class SitePlansProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      asideData: [],
      isLoading: true,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    axios.get(consts.getContentUrl()).then((resp) => {
      this.setState({
        ...this.state,
        asideData: resp.data.menu[0].produtos,
        isLoading: false,
      });
    });
  }

  render() {
    const page = this.props.page;
    const [site, logo, logoNegativo] = consts.getInternalPageData(page);

    return (
      <div className={`internal-page plans-page ${page}-page`}>
        <div className="main-container">
          <NavBar
            site={site}
            logo={logo}
            logoNegativo={logoNegativo}
            page={page}
          />
          {this.state.isLoading ? (
            <div className="loader"></div>
          ) : (
            <Container
              asideData={this.state.asideData}
              planKey={
                this.props.location.state ? this.props.location.state.key : null
              }
            />
          )}
          <Footer companyName={site} />
        </div>
      </div>
    );
  }
}
export default SitePlansProducts;
