import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
// import Page from "react-page-loading"

//Package CSS & JS
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "animate.css/animate.min.css";

//Template SCSS & CSS Style
import "./assets/css/style.scss";
import "./assets/css/responsive.scss";

//Component Import
import UniaoSeguradora from "./pages/UniaoSeguradora";
import SiteAbout from "./pages/SiteAbout";
import SiteContact from "./pages/SiteContact";
import SiteTravelInsurance from "./pages/SiteTravelInsurance";
import SiteNews from "./pages/SiteNews";
import SiteProducts from "./pages/SiteProducts";
import SiteCertificate from "./pages/SiteCertificate";
import SitePartners from "./pages/SitePartners";

class App extends Component {
  render() {
    return (
      <div className="App">
        <Switch>
          <Route
            exact
            path="/"
            render={(props) => (
              <UniaoSeguradora
                {...props}
                page="uniao-seguradora"
                title="União Seguradora"
              />
            )}
          />
          <Route
            exact
            path="/sobre"
            render={(props) => (
              <SiteAbout
                {...props}
                page="uniao-seguradora"
                title="União Seguradora"
              />
            )}
          />
          <Route
            exact
            path="/noticias"
            render={(props) => (
              <SiteNews
                {...props}
                page="uniao-seguradora"
                title="União Seguradora"
              />
            )}
          />
          <Route
            exact
            path="/contato"
            render={(props) => (
              <SiteContact
                {...props}
                page="uniao-seguradora"
                title="União Seguradora"
              />
            )}
          />
          <Route
            exact
            path="/seguro-viagem"
            render={(props) => (
              <SiteTravelInsurance
                {...props}
                page="uniao-seguradora"
                title="União Seguradora"
              />
            )}
          />
          <Route
            exact
            path="/produtos"
            render={(props) => (
              <SiteProducts
                {...props}
                page="uniao-seguradora"
                title="União Seguradora"
              />
            )}
          />
          <Route
            exact
            path="/certificado"
            render={(props) => (
              <SiteCertificate
                {...props}
                page="uniao-seguradora"
                title="União Seguradora"
              />
            )}
          />
          <Route
            exact
            path="/representantes"
            render={(props) => (
              <SitePartners
                {...props}
                page="uniao-seguradora"
                title="União Seguradora"
              />
            )}
          />
        </Switch>
      </div>
    );
  }
}
export default App;
