import React, { Component } from "react";
import { Nav, Modal } from "react-bootstrap";
import PropTypes from 'prop-types';
import Icofont from "react-icofont";
import global from "../utils/global"

export class SideNav extends Component {
  constructor(props, context) {
    super(props, context);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.state = {
      show: false,
    };
  }

  handleClose() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });    
  }

  render() {
    return (
      <React.Fragment>
        <Nav.Link onClick={this.handleShow}><span></span></Nav.Link>

        <Modal show={this.state.show} onHide={this.handleClose} className={"right " + this.props.page + "-page"}>
          <Modal.Header closeButton>
          </Modal.Header>
          <Modal.Body>
            <div className="logo">
              <img src={this.props.logo} alt={this.props.site} />
            </div>
            <p>{this.props.content}</p>
            <ul className="modal-contact-info">
              <li><Icofont icon="icofont-google-map" /> <b>{this.props.locationbolt}</b>{this.props.locationnormal}</li>
              <li><Icofont icon="icofont-ui-call" /> <b>{global.setPhoneMask(this.props.phonebolt)}</b>{this.props.phonenormal}</li>
              <li><Icofont icon="icofont-envelope" /> <b>{this.props.mailbolot}</b>{this.props.mailnormal}</li>
            </ul>
            {this.props.hasSocialLinks &&
              <ul className="social-links">
                <li><a href={this.props.facebooklink}><Icofont icon="icofont-facebook" /></a></li>
              </ul>
            }
          </Modal.Body>
        </Modal>
      </React.Fragment>

    );
  }
}
SideNav.PropsTypes = {
  logotext: PropTypes.string,
  logotextspan: PropTypes.string,
  content: PropTypes.string,
  locationbolt: PropTypes.string,
  locationnormal: PropTypes.string,
  phonebolt: PropTypes.string,
  phonenormal: PropTypes.string,
  mailbolot: PropTypes.string,
  mailnormal: PropTypes.string,
  facebooklink: PropTypes.string,
  twitterlink: PropTypes.string,
  instagramlink: PropTypes.string,
  linkedinlink: PropTypes.string,
  pinterestlink: PropTypes.string,

}
export default SideNav;
