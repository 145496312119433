import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

export class Services extends Component {
    render() {
        //Start Services Loop
        const servicedata = this.props.servicesData.map((services, index) => {
            const urlHash = {
                'Pecúlio': 'peculio',
                'Seguro': 'seguro',
                'Assistências': 'assistencias'
            };
            return (
                <div className="col-lg-3 col-md-6" key={index}>
                    <div className="single-services">
                        <Link
                            to={`${this.props.productsPageUrl}#${urlHash[services.title]}`}
                        >
                            <img src={services.icon} alt={"Ícone " + services.title} />
                            <h3>{services.title}</h3>
                            <p>{services.content}</p>

                            <div className="bg-number">{services.bgnumber}</div>
                        </Link>
                    </div>
                </div>
            );
        });
        //End Services Loop
        return (
            <React.Fragment>
                <section id="services" className="services-area ptb-50">
                    <div className="container">
                        <div className="section-title">
                            <h2>
                                {this.props.sectionTitle} <b>{this.props.sectionTitleSpan}</b>
                            </h2>
                        </div>
                        <div className="row">
                            <div class="col-lg-3 col-md-6">
                                <div class="single-services">
                                    <a
                                        href="https://storage.googleapis.com/aspecirlv/files/PORTFOLIO%20-%20UNIAO%20SEG%20-%202024.pdf"
                                        target="_blank"
                                        rel="noopener noreferrer" 
                                    >
                                        <img
                                            src="data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEASABIAAD/2wBDAAoHBwgHBgoICAgLCgoLDhgQDg0NDh0VFhEYIx8lJCIfIiEmKzcvJik0KSEiMEExNDk7Pj4+JS5ESUM8SDc9Pjv/2wBDAQoLCw4NDhwQEBw7KCIoOzs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozv/wAARCAAyADIDASIAAhEBAxEB/8QAGwABAQACAwEAAAAAAAAAAAAAAAYFBwECBAj/xAA1EAABAwMCAwUDDQEAAAAAAAABAAIDBAURBhIHITETIkFRYTZSwRQXMlVxdHWRlKGxwtLw/8QAGQEAAgMBAAAAAAAAAAAAAAAAAAQCAwUB/8QAJBEAAgICAQMEAwAAAAAAAAAAAAECEQMSIQQTQQUUMdEiYfD/2gAMAwEAAhEDEQA/ANzIixWotRUGmbW+urn8ukcbfpSOxyAUoxcnrH5ONpK2ZVdWPZI3cx7XN82nIWgr1xM1JdpndlVmhgPSKn7vL1d1Kxlr1jqCzECiuczWAk9m87mHJyeR9VqL0rK422rF31Mb+D6SRReheIMGqGGjrGsprkwZ2NPdlHm318x/wtFm5cU8UtZrkvjJSVoIiKskFL37RNPqm5fKLvVzmnhG2mp4XBob7znHBySf2wqhSnEa719l00yqt1QaeZ1Sxm8NB5HOeoV+DfuJQdNkJ1rySF44Zaestwhqq+9SU1rf3djhulc/wAIHTGTnHh6rE2rRNh1BHTU1BeXQ3F0rzLDK0kGIOPNvId7bg4z+SVVz1TddcGz0917SS31UwgmnYwCMNDgXOw33QfBc6nuWpbPUUNzGoaauawubDLCxodG5zRuBaWjqOnwW3Hv0ouf5NfdeBR6cuuP79ltT8KrVbZIqu11tZBXU7g+KV7w5u4eYx0Pirdu7aN+N2OeOmVAaOvl4qtTw0VdcpKqGa1sqi17GDD3Y6YA5LYKx+p7myWR2NY9auKoIiJUsCiOLXshH99i/srdeC82Sgv8AQGiuUJlh3BwAeWkOHQ5B9VdgmseSMn4IzW0WkaTNTFR8UrjNNcX27bW1G2pa3cGO3OxuHi09D9q9fEautFVBSMhfRz3VryaiekjLWlm0YDiep/hbFHDPSRy6a2vnkcSXSSVMpc4nxJ3Ln5s9HfUw/US/6Wp73BvGfPCrx9i3anTXHJN6J9taL8Ah+C2csHZdH2aw1bquhhl7cs7MPkmc/az3Rk4xyWcWb1OSOSdxGMcXFUwiIlyYREQAREQAREQAREQB/9k="
                                            alt="Ícone Portfolio"
                                        />
                                        <h3>Portfólio</h3>
                                        <p>Portfólio de produtos da UNIÃO Seguradora</p>
                                        <div class="bg-number"></div>
                                    </a>
                                </div>
                            </div>
                            {servicedata}
                        </div>
                    </div>
                    <div className="animation-box5">
                        <img src={this.props.animationImg} className="rotateme" alt="vector" />
                    </div>
                </section>
            </React.Fragment>
        );
    }
}
Services.propsTypes = {
    sectionTitle: PropTypes.string,
    sectionTitleSpan: PropTypes.string,
    animationImg: PropTypes.string,
    productsPageUrl: PropTypes.string,
    servicesData: PropTypes.array,
};
Services.defaultProps = {
    sectionTitle: "",
    sectionTitleSpan: "",
    animationImg: require("../../src/assets/img/vector-bg.png"),
    productsPageUrl: "",
    servicesData: [],
};
export default Services;
