import React, { Component } from "react";
import Propstypes from "prop-types";

import Aside from "./Aside";
import Main from "./Main";

export class Container extends Component {
	constructor(props) {
		super(props);
		this.state = {
			banner: "",
			currentPlan: "",
			currentKey: "",
		};
	}

	componentDidMount() {
		if (this.props.planKey) {
			let keys = this.props.planKey.split("#");
			this.props.asideData.forEach((element) => {
				if (element.key === keys[0]) {
					this.setState({
						banner: element.banner,
						currentKey: keys[0],
					});

					if (typeof keys[1] === "undefined")
						this.setState({
							currentPlan: element.topics[0],
						});
					else {
						element.topics.forEach((topic) => {
							if (topic.key === keys[1]) {
								this.setState({
									currentPlan: topic,
								});
							}
						});
					}
				}
			});
		} else {
			const urlKey = window.location.href.split("#");
			this.props.asideData.forEach((element) => {
				if (element.key === urlKey[1]) {
					this.setState({
						currentPlan: element.topics[0],
					});
				} else if (!window.location.href.includes("#")) {
					this.setState({
						currentPlan: this.props.asideData[0].topics[0],
					});
				}
			});
		}
	}

	handleAsideClick = (banner, topic, planKey) => {
		this.setState({ banner: banner, currentPlan: topic, currentKey: planKey });
	};

	render() {
		return (
			<React.Fragment>
				<section className="plans-container">
					<div className="container">
						{this.state.banner && <img src={this.state.banner} className="plans-banner" alt="" />}
						<div className="row">
							<div className="col-lg-4">
								<Aside
									asideData={this.props.asideData}
									handleAsideClick={this.handleAsideClick}
									currentKey={this.state.currentKey}
									currentPlan={this.state.currentPlan}
									defaultPlanKey='peculio'
								/>
							</div>
							<div className="col-lg-8">
								<Main plan={this.state.currentPlan} />
							</div>
						</div>
					</div>
				</section>
			</React.Fragment>
		);
	}
}
Container.Propstypes = {
	asideData: Propstypes.array,
};
Container.defaultProps = {
	asideData: [],
};
export default Container;
