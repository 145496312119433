import React, { useState, useEffect } from "react";

import consts from "../utils/constants";

import axios from "axios";

const Partners = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    setLoading(true);
    axios.get(consts.getRepresentative()).then((resp) => {
      const data = resp.data.results;
      if (data.length > 0) {
        let uf = {};
        data.forEach((element) => {
          if (uf.hasOwnProperty(element.address_state)) {
            uf[element.address_state].push(element);
          } else {
            uf[element.address_state] = [element];
          }
        });
        setData(uf);
      } else {
        window.location.href = "/contato";
      }
      setLoading(false);
    });
  }, []);

  return (
    <>
      {loading ? (
        <div className="loader"></div>
      ) : (
        <div className="partners_represets_container">
          <div className="partners_represets_header">
            <p>Parceiros</p>
            <span>Representantes</span>
          </div>
          <div className="partners_represets_content">
            {Object.keys(data).map((state, index) => (
              <div key={index}>
                <h4>{state}</h4>
                {data[state].map((entry, index) => (
                  <div
                    key={index}
                    className="partners_represent_inside_content"
                  >
                    <div className="partners_represent_inside_content_header">
                      <p>{entry.first_name}</p>
                      <p>Fone: {entry.phone_number}</p>
                    </div>
                    <div className="partners_represent_inside_content_local">
                      <p>{entry.company_name}</p>
                      <p>
                        {entry.address_street}, {entry.address_district},
                        {entry.address_city}, {entry.address_state}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default Partners;
